<template>
  <div>
    <div class="card" :class="{ card1:customer==='qingtian'}">
      <div class="title">
        <div class="left">
          <img
            v-if="customer==='qingtian'"
            src="@/assets/images/qingtian/基本信息.png"
            alt=""
          />
          <span v-if="customer==='jinlang'"></span>&nbsp;基本信息
        </div>
      </div>
      <div class="content">
        <ul>
          <li>东西长度（m）</li>
          <!-- <li>{{ baseInfo.EastWestLength }}</li> -->
          <li>{{ inpValW }}</li>
        </ul>
        <ul>
          <li>南北宽度（m）</li>
          <!-- <li>{{ baseInfo.NorthSouthWidth }}</li> -->
          <li>{{ inpValH }}</li>
        </ul>
        <ul>
          <li>南坡宽度（m）</li>
          <!-- <li>{{ baseInfo.SouthSlopeWidth }}</li> -->
          <li>{{ southSlopeWidth }}</li>
        </ul>
        <ul>
          <li>南坡坡度（°）</li>
          <li>{{ baseInfo.southSlope }}</li>
        </ul>
        <ul>
          <li>北坡宽度（m）</li>
          <!-- <li>{{ baseInfo.NorthSlopeWidth }}</li> -->
          <li>{{ northSlopeWidth }}</li>
        </ul>
        <ul>
          <li>北坡坡度（°）</li>
          <li>{{ baseInfo.northSlope }}</li>
        </ul>
        <ul>
          <li>屋檐高度（m）</li>
          <li>{{ baseInfo.eavesHigh }}</li>
        </ul>

        <!-- ///// -->
        <ul>
          <li>房屋高度（m）</li>
          <li>{{ baseInfo.houseHeight }}</li>
        </ul>
        <ul>
          <li>房屋朝向</li>
          <li>{{ baseInfo.houseFacing }}</li>
        </ul>
        <ul v-if="baseInfo.houseFacing !== '正南'">
          <li>偏向度数</li>
          <li>{{ baseInfo.facingDegree }}</li>
        </ul>
        <ul>
          <li>距并网点距离（m）</li>
          <li>{{ baseInfo.gridConnectionDistance }}</li>
        </ul>
        <ul>
          <li>屋脊高度（m）</li>
          <li>{{ baseInfo.ridgeHigh }}</li>
        </ul>
        <ul>
          <li>屋面类型</li>
          <li>{{ baseInfo.roofingType }}</li>
        </ul>
        <ul
          v-if="baseInfo.roofingType == '预制板' || baseInfo.roofingType == '现浇板'"
        >
          <li>屋面厚度（m）</li>
          <li>{{ baseInfo.roofSlabThickness }}</li>
        </ul>
        <div style="margin-bottom: 15px" v-if="baseInfo.roofingType == '木望板'">
          <ul>
            <li>木檩直径（m）</li>
            <li>{{ baseInfo.woodPurlinDiameter }}</li>
          </ul>
          <ul>
            <li>木檩间距（m）</li>
            <li>{{ baseInfo.woodPurlinSpacing }}</li>
          </ul>
          <ul>
            <li>木望板厚度（m）</li>
            <li>{{ baseInfo.woodWatchboardThickness }}</li>
          </ul>
        </div>
        <div style="margin-bottom: 15px" v-if="baseInfo.roofingType == '檩橼结构'">
          <ul>
            <li>木檩条直径（m）</li>
            <li>{{ baseInfo.purlinDiameter }}</li>
          </ul>
          <ul>
            <li>木檩条间距（m）</li>
            <li>{{ baseInfo.purlinSpacing }}</li>
          </ul>
          <ul>
            <li>人字梁东西跨度（m）</li>
            <li>{{ baseInfo.herringboneBeamEastWest }}</li>
          </ul>
          <ul>
            <li>人字梁南北跨度（m）</li>
            <li>{{ baseInfo.herringboneBeamNorthSouth }}</li>
          </ul>
          <ul>
            <li>人字梁主梁直径（m）</li>
            <li>{{ baseInfo.herringboneGirderDiameter }}</li>
          </ul>
        </div>
        <ul>
          <li>安装方式</li>
          <li>{{ baseInfo.installationMethod }}</li>
        </ul>
        <!-- <ul>
          <li>南侧屋檐</li>
          <li>{{ baseInfo.SouthroofEnd }}</li>
        </ul>
        <ul>
          <li>屋脊可探出</li>
          <li>{{ baseInfo.RidgeofRoofPokesOut }}</li>
        </ul>
        <ul>
          <li>北侧屋檐</li>
          <li>{{ baseInfo.NorthroofEnd }}</li>
        </ul> -->
      </div>
      <div>
        <img class="canvasimg" :src="canvasImg" alt="">
      </div>
    </div>
    <div class="btnBox">
      <div class="cancel" @click="cancelEvent">取消</div>
      <div class="cancel save" @click="save">保存数据</div>
      <div class="determine" @click="submit">提交踏勘信息</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { useRouter } from 'vue-router'
import { reactive, toRefs, getCurrentInstance } from 'vue'
import { Dialog, Toast } from 'vant'
import { AddObliqueRoof, AddTask, GetTaskState, ProjectErrorInfo } from "@/api/api"

export default {
  setup() {
    // const baseInfo = JSON.parse(sessionStorage.getItem('baseInfo'))
    const router = useRouter()
    const globleData = getCurrentInstance().appContext.app.config.globalProperties.$globleData
    // console.log(baseInfo, '基础信息')
    var baseInfo = JSON.parse(sessionStorage.getItem('requestObliqueRoof'))
    // baseInfo.SouthSlopeWidth = baseInfo.SouthSlopeWidth / 1000
    baseInfo.southSlope = baseInfo.southSlope + 0
    // baseInfo.NorthSlopeWidth = baseInfo.NorthSlopeWidth / 1000
    baseInfo.northSlope = baseInfo.northSlope + 0
    baseInfo.eavesHigh = baseInfo.eavesHigh / 1000
    // baseInfo.topViewPic = baseInfo.topViewPic
    // baseInfo.sideViewPic = baseInfo.sideViewPic
    // baseInfo.rearViewPic = baseInfo.rearViewPic
    // baseInfo.frontViewPic = baseInfo.frontViewPic
    baseInfo.houseHeight = baseInfo.houseHeight / 1000
    if (baseInfo.houseFacing === 1) {
      baseInfo.houseFacing = "正南"
    } else if (baseInfo.houseFacing === 2) {
      baseInfo.houseFacing = "南偏东"
    } else if (baseInfo.houseFacing === 3) {
      baseInfo.houseFacing = "南偏西"
    }
    baseInfo.facingDegree = baseInfo.facingDegree + 0
    baseInfo.gridConnectionDistance = baseInfo.gridConnectionDistance / 1000
    baseInfo.ridgeHigh = baseInfo.ridgeHigh / 1000
    if (baseInfo.roofingType === 1) {
      baseInfo.roofingType = "现浇板"
    } else if (baseInfo.roofingType === 2) {
      baseInfo.roofingType = "预制板"
    } else if (baseInfo.roofingType === 3) {
      baseInfo.roofingType = "木望板"
    } else if (baseInfo.roofingType === 4) {
      baseInfo.roofingType = "凛橼结构"
    }
    if (baseInfo.southRoofEnd === 1) {
      baseInfo.SouthroofEnd = '标准前支'
    } else if (baseInfo.southRoofEnd === 2) {
      baseInfo.SouthroofEnd = '无水平杆前支'
    } else if (baseInfo.southRoofEnd === 3) {
      baseInfo.SouthroofEnd = '前排挂钩'
    } else if (baseInfo.southRoofEnd === 4) {
      baseInfo.SouthroofEnd = '全挂钩'
    }
    if (baseInfo.ridgeofRoofPokesOut === 1) {
      baseInfo.RidgeofRoofPokesOut = '0mm'
    } else if (baseInfo.ridgeofRoofPokesOut === 2) {
      baseInfo.RidgeofRoofPokesOut = '500mm'
    } else if (baseInfo.ridgeofRoofPokesOut === 3) {
      baseInfo.RidgeofRoofPokesOut = '800mm'
    }
    if (baseInfo.northRoofEnd === 1) {
      baseInfo.NorthroofEnd = '标准背拉'
    } else if (baseInfo.northRoofEnd === 2) {
      baseInfo.NorthroofEnd = '挂钩背拉'
    }
    baseInfo.roofSlabThickness = baseInfo.roofSlabThickness / 1000
    baseInfo.woodPurlinDiameter = baseInfo.woodPurlinDiameter / 1000
    baseInfo.woodPurlinSpacing = baseInfo.woodPurlinSpacing / 1000
    baseInfo.woodWatchboardThickness = baseInfo.woodWatchboardThickness / 1000
    baseInfo.purlinDiameter = baseInfo.purlinDiameter / 1000
    baseInfo.purlinSpacing = baseInfo.purlinSpacing / 1000
    baseInfo.herringboneBeamEastWest = baseInfo.herringboneBeamEastWest / 1000
    baseInfo.herringboneBeamNorthSouth = baseInfo.herringboneBeamNorthSouth / 1000
    baseInfo.herringboneGirderDiameter = baseInfo.herringboneGirderDiameter / 1000
    if (baseInfo.installationMethod === 1) {
      baseInfo.installationMethod = "前支背拉"
    } else if (baseInfo.installationMethod === 2) {
      baseInfo.installationMethod = "挂钩"
    }
    if (baseInfo.SouthroofEnd === 1) {
      baseInfo.SouthroofEnd = '标准前支'
    } else if (baseInfo.SouthroofEnd === 2) {
      baseInfo.SouthroofEnd = '无水平杆前支'
    } else if (baseInfo.SouthroofEnd === 3) {
      baseInfo.SouthroofEnd = '前排挂钩'
    } else if (baseInfo.SouthroofEnd === 4) {
      baseInfo.SouthroofEnd = '全挂钩'
    }
    if (baseInfo.RidgeofRoofPokesOut === 1) {
      baseInfo.RidgeofRoofPokesOut = '0mm'
    } else if (baseInfo.RidgeofRoofPokesOut === 2) {
      baseInfo.RidgeofRoofPokesOut = '500mm'
    } else if (baseInfo.RidgeofRoofPokesOut === 3) {
      baseInfo.RidgeofRoofPokesOut = '800mm'
    }
    if (baseInfo.NorthroofEnd === 1) {
      baseInfo.NorthroofEnd = '标准背拉'
    } else if (baseInfo.NorthroofEnd === 2) {
      baseInfo.NorthroofEnd = '挂钩背拉'
    }
    const data = reactive({
      customer: window.customer,
      toast: null,
      timer: null,
      second: 40,
      timeLessen: 20,
      inpValW: sessionStorage.getItem('inpValW') / 100,
      inpValH: (sessionStorage.getItem('inpValH') / 100) + (sessionStorage.getItem('inpValH1') / 100),
      southSlopeWidth: sessionStorage.getItem('inpValH1') / 100,
      northSlopeWidth: sessionStorage.getItem('inpValH') / 100,
      canvasImg: sessionStorage.getItem('flatCanvas'),

    })
    const methodsMap = {
      cancelEvent() {
        router.back()
      },
      getDate() {
        var datetime = new Date();
        var year = datetime.getFullYear();
        var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
        var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
        return year + '/' + month + '/' + date
      },
      save() {
        sessionStorage.removeItem("OinpValW")
        sessionStorage.removeItem("OinpValH")
        const obj1 = JSON.parse(sessionStorage.getItem('projectAddRequest'))
        obj1.draftImages = sessionStorage.getItem('grassImg')
        obj1.RoofType = 2
        var aftData = JSON.parse(sessionStorage.getItem('requestObliqueRoof'))

        aftData.eastWestLength = sessionStorage.getItem('inpValW') * 10
        aftData.northSouthWidth = (sessionStorage.getItem('inpValH') * 10) + (sessionStorage.getItem('inpValH1') * 10)
        aftData.SouthSlopeWidth = sessionStorage.getItem('inpValH1') * 10
        aftData.northSlopeWidth = sessionStorage.getItem('inpValH') * 10
        aftData.roofType = 2
        if (aftData.topViewPic === 0) {
          aftData.topViewPic = null
        }
        if (aftData.sideViewPic === 0) {
          aftData.sideViewPic = null
        }
        if (aftData.rearViewPic === 0) {
          aftData.rearViewPic = null
        }
        if (aftData.frontViewPic === 0) {
          aftData.frontViewPic = null
        }
        // 上传屋顶数据
        const projectJson = {
          projectAddRequest: obj1,
          requestObliqueRoof: aftData,
          SurveyTime: methodsMap.getDate(),
          ...globleData
        }
        console.log(JSON.stringify(projectJson));
        AddObliqueRoof(projectJson).then(res => { // 添加平屋顶的踏勘表单数据给后台
          if (res.data.success) {
            Dialog.alert({
              message: "保存成功"
            }).then(() => {
              router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })
            })
          }
        })
      },
      submit() {
        sessionStorage.removeItem("OinpValW")
        sessionStorage.removeItem("OinpValH")
        const obj1 = JSON.parse(sessionStorage.getItem('projectAddRequest'))

        obj1.draftImages = sessionStorage.getItem('grassImg')
        obj1.RoofType = 2

        var aftData = JSON.parse(sessionStorage.getItem('requestObliqueRoof'))
        // for (const i in aftData) {
        // if (i === 'EastWestLength') {
        //   delete aftData[i]
        // }
        // if (i === 'NorthSouthWidth') {
        //   delete aftData[i]
        // }
        // }
        aftData.eastWestLength = sessionStorage.getItem('inpValW') * 10
        aftData.northSouthWidth = (sessionStorage.getItem('inpValH') * 10) + (sessionStorage.getItem('inpValH1') * 10)
        aftData.SouthSlopeWidth = sessionStorage.getItem('inpValH1') * 10
        aftData.NorthSlopeWidth = sessionStorage.getItem('inpValH') * 10
        aftData.roofType = 1

        // 上传屋顶数据
        const projectJson = {
          projectAddRequest: obj1,
          requestObliqueRoof: aftData,
          SurveyTime: methodsMap.getDate(),
          ...globleData
        }
        // console.log(JSON.stringify(projectJson));
        data.toast = Toast.loading({
          message: '处理中...',
          forbidClick: true,
          duration: 0
        })
        AddObliqueRoof(projectJson).then(res => { // 添加平屋顶的踏勘表单数据给后台
          if (res.data.success) {
            AddTask(4).then(res => { // 第一次添加出图任务
              if (res.data.success) {
                data.timer = setInterval(() => { // 出图需要一定的时间,所以加循环定时器轮询出图的状态,
                  GetTaskState(4).then(ress => {
                    console.log(ress, "ress99999")
                    data.timeLessen--
                    if (ress.data.data.jsonStatus === 0 || ress.data.data.jsonStatus === 1) {
                      if (data.timeLessen === 0) {
                        data.toast.clear()
                        Dialog.alert({
                          message: "出图失败,请重新尝试"
                        }).then(() => {
                          router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })
                        })
                      }
                    } else if (ress.data.data.jsonStatus === 2) {
                      clearInterval(data.timer)
                      data.toast.clear()
                      Dialog.alert({
                        message: ress.data.message !== "" ? ress.data.message : "出图成功"
                      }).then(() => {
                        router.push('/confirmLayout')
                      })
                    } else if (ress.data.data.jsonStatus === 3) {
                      clearInterval(data.timer)
                      data.toast.clear()
                      ProjectErrorInfo().then(result => { // 调用这个异常接口,在前端弹出异常的内容
                        if (result.data.success) {
                          if (result.data === null) {
                            Dialog.alert({
                              message: '出图错误'
                            }).then(() => {
                              router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })// 异常就返回首页
                            })
                          }
                          console.log(result.data, 'resdata2222222')
                          Dialog.alert({
                            // message: result.data.data.errorDetail
                            // message: result.data.data.error
                            message: result.data.data.errorDetail !== null ? result.data.data.errorDetail : result.data.data.error
                          }).then(() => {
                            router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })// 异常就返回首页
                          })
                        }
                      })
                    }
                  })
                }, 3000)
              } else {
                Toast.clear()
                Dialog.alert({
                  message: res.data.message
                })
              }
            })
          }
        })
      }
    }
    return { ...toRefs(data), baseInfo, ...methodsMap }
  }
}
</script>
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}
.card {
  margin: 0 auto;
  margin-top: 20px;
  padding: 16px 12px;
  width: 343px;
  box-shadow: 0px 7px 43px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background: #ffffff;
  min-height: 127px;
  .title {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    color: #1a1a1a;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(199, 201, 204, 0.4);
    .left {
      display: flex;
      align-items: center;
       img {
        width: 25px;
        height: 25px;
      }
    }
    span {
      display: inline-block;
      width: 4px;
      height: 19px;
      background: #04cc66;
    }
  }
  .content {
    margin-top: 15px;
  }
  ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 14px;
    color: #7c8190;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
     li:nth-last-child(1) {
      font-weight: bold;
      color: #1a1a1a;
    }
  }
}
.card1 {
  margin-top: 12px;
  border-radius: 0;
  width: 375px;
  padding: 12px 16px;
}
.btnBox {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  margin-top: 28px;
  .cancel,
  .determine {
    display: inline-block;
    height: 46px;
    line-height: 46px;
    text-align: center;
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    width: 100px;
  }
  .cancel {
    border: 1px solid #04cc66;
    color: #04cc66;
  }
  .save{
    background: #8ff77a;
  }
  .determine {
    background: #04cc66;
  }
}
.canvasimg{
  width: 100vw;
  margin-top: 20px;
  margin-left: -16px;
  height: auto;
  background-color: #628cd4;
}
</style>
